"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMemoBoard = exports.MemoBoard = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/document-editor/lib-es2015");
const lib_es2015_3 = require("@hodol/web-window/lib-es2015");
const lib_es2015_4 = require("@hodol/dom-helper/lib-es2015");
const apis_1 = require("./apis");
const entity_1 = require("./entity");
class MemoBoard extends HTMLElement {
    constructor() {
        super();
        this.isPrivate = (0, lib_es2015_4.getAttributeOrThrowError)(this, 'is-private') === 'private';
        this.apis = new apis_1.default();
        this.convertRecordableSourceToPresentable = (htmlSource, numberingId) => {
            const recordable = (0, lib_es2015_4.buildNodeByOuterHTML)(htmlSource);
            this.documentEditor.replaceParagraphNumberingId(recordable, 'global', numberingId);
            this.documentEditor.resetParagraphNumberingCounter(numberingId, '1.');
            this.documentEditor.convertRecordableToPresentable(recordable);
            return recordable;
        };
        this.convertRecordableSourceToEditable = (htmlSource) => {
            const recordable = (0, lib_es2015_4.buildNodeByOuterHTML)(htmlSource);
            this.documentEditor.convertRecordableToEditable(recordable);
            return recordable;
        };
        this.setupMemoBoard = () => {
            this.desktop = new lib_es2015_3.Desktop(this.memoBoardContainer);
            this.settings = new lib_es2015_3.Settings(this.desktop);
        };
        this.buildPresentableMemo = (w, entity) => {
            w.setValue('pk', entity.pk);
            if (entity.pk !== 0)
                w.setStyle(JSON.parse(entity.style_set));
            console.log(`memoPK: ${entity.pk}, parsedStyle:`, entity.style_set);
            const title = this.convertRecordableSourceToPresentable(entity.title, `temp-numbering-id-${entity.pk}`);
            w.setTitle(title);
            const content = this.convertRecordableSourceToPresentable(entity.content, `temp-numbering-id-${entity.pk}`);
            const scrollView = (0, lib_es2015_4.buildNodeByOuterHTML)(`<div class="scroll-view"></div>`);
            scrollView.appendChild(content);
            w.setBody(scrollView);
        };
        this.buildEditableMemo = (w, entity) => {
            w.setValue('pk', entity.pk);
            if (entity.pk !== 0)
                w.setStyle(JSON.parse(entity.style_set));
            console.log(`memoPK: ${entity.pk}, parsedStyle:`, entity.style_set);
            const title = this.convertRecordableSourceToEditable(entity.title);
            w.setTitle(title);
            const content = this.convertRecordableSourceToEditable(entity.content);
            const scrollView = (0, lib_es2015_4.buildNodeByOuterHTML)(`<div class="scroll-view"></div>`);
            scrollView.appendChild(content);
            w.setBody(scrollView);
        };
        this.buildAndPutMemo = (entity) => {
            const w = new lib_es2015_3.Window(this.desktop, lib_es2015_3.DefaultStyleSet, this.settings);
            this.buildPresentableMemo(w, entity);
            const footerBtnContainer = (0, lib_es2015_4.buildNodeByOuterHTML)(`
      <div style="display: flex; justify-content: flex-end; padding: 0.2rem; box-sizing: border-box; visibility: hidden;">
        <button class="edit">Edit</button>
        <button class="delete">Delete</button>
        <button class="save" style="display: none;">Save</button>
        <button class="cancel" style="display: none;">Cancel</button>
      </div>`);
            const editButton = (0, lib_es2015_4.getQuerySelectOrThrowError)(footerBtnContainer, 'button.edit');
            const deleteButton = (0, lib_es2015_4.getQuerySelectOrThrowError)(footerBtnContainer, 'button.delete');
            const saveButton = (0, lib_es2015_4.getQuerySelectOrThrowError)(footerBtnContainer, 'button.save');
            const cancelButton = (0, lib_es2015_4.getQuerySelectOrThrowError)(footerBtnContainer, 'button.cancel');
            w.window.addEventListener('mouseenter', () => {
                footerBtnContainer.style.setProperty('visibility', 'visible');
            });
            w.window.addEventListener('mouseleave', () => {
                footerBtnContainer.style.setProperty('visibility', 'hidden');
            });
            editButton.addEventListener('click', () => {
                editButton.style.setProperty('display', 'none');
                deleteButton.style.setProperty('display', 'none');
                saveButton.style.setProperty('display', 'inline-block');
                cancelButton.style.setProperty('display', 'inline-block');
                if (entity.pk === 0) {
                    this.buildEditableMemo(w, entity);
                    this.documentEditor.contentEditor = w.getBody();
                    this.documentEditor.addDefaultEventListenersOnContentEditor();
                    this.toggleEdit(true);
                    const toolbarBottom = this.toolbarContainer.getBoundingClientRect().bottom;
                    if (w.getYPosition() < toolbarBottom) {
                        w.setYPosition(toolbarBottom);
                    }
                }
                else {
                    void (() => __awaiter(this, void 0, void 0, function* () {
                        yield this.apis.updateMemoStyles([{ pk: entity.pk, style_set: JSON.stringify(w.getStyleSet()) }]);
                        const oldEntity = yield this.apis.getMemoByPK({ pk: entity.pk });
                        if (oldEntity === null) {
                            alert(`fail to get old memo!, try later.`);
                            return;
                        }
                        this.buildEditableMemo(w, oldEntity);
                        this.documentEditor.contentEditor = w.getBody();
                        this.documentEditor.addDefaultEventListenersOnContentEditor();
                        this.toggleEdit(true);
                        const toolbarBottom = this.toolbarContainer.getBoundingClientRect().bottom;
                        if (w.getYPosition() < toolbarBottom) {
                            w.setYPosition(toolbarBottom);
                        }
                    }))();
                }
            });
            deleteButton.addEventListener('click', (e) => {
                if (!confirm(`Are you sure delete the memo? title: ${w.getTitle().textContent}, pk: ${entity.pk}`)) {
                    return;
                }
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const resp = yield this.apis.deleteMemo(entity);
                    if (resp === null) {
                        alert(`failed to delete memo!`);
                        return;
                    }
                    w.onClose(e);
                }))();
            });
            cancelButton.addEventListener('click', () => {
                if (!confirm(`You will lost all modification, continue?`))
                    return;
                editButton.style.setProperty('display', 'inline-block');
                deleteButton.style.setProperty('display', 'inline-block');
                saveButton.style.setProperty('display', 'none');
                cancelButton.style.setProperty('display', 'none');
                if (entity.pk === 0) {
                    this.buildPresentableMemo(w, entity);
                    this.toggleEdit(false);
                }
                else {
                    void (() => __awaiter(this, void 0, void 0, function* () {
                        yield this.apis.updateMemoStyles([{ pk: entity.pk, style_set: JSON.stringify(w.getStyleSet()) }]);
                        const oldEntity = yield this.apis.getMemoByPK({ pk: entity.pk });
                        if (oldEntity === null) {
                            alert(`fail to get old memo!, try later.`);
                            return;
                        }
                        this.buildPresentableMemo(w, oldEntity);
                        this.toggleEdit(false);
                    }))();
                }
            });
            saveButton.addEventListener('click', () => {
                void (() => __awaiter(this, void 0, void 0, function* () {
                    var _a;
                    if (!confirm(`Old data will be lost, continue?`))
                        return;
                    const clonedTitle = (_a = w.getTitle().parentNode) === null || _a === void 0 ? void 0 : _a.cloneNode(true);
                    const clonedContent = w.getBody().cloneNode(true);
                    this.documentEditor.convertEditableToRecordable(clonedTitle);
                    this.documentEditor.convertEditableToRecordable(clonedContent);
                    if (entity.pk === 0) {
                        const insertedPKOrNull = yield this.apis.insertMemo({
                            title: clonedTitle.innerHTML,
                            content: clonedContent.innerHTML,
                            style_set: JSON.stringify(w.getStyleSet()),
                            is_private: this.isPrivate,
                        });
                        if (insertedPKOrNull === null) {
                            alert(`failed to insert! try later!`);
                            return;
                        }
                        entity.pk = insertedPKOrNull;
                    }
                    else {
                        const resp = yield this.apis.updateMemo(entity.pk, {
                            title: clonedTitle.innerHTML,
                            content: clonedContent.innerHTML,
                            style_set: JSON.stringify(w.getStyleSet()),
                            is_private: this.isPrivate,
                        });
                        if (resp === null) {
                            alert(`failed to insert! try later!`);
                            return;
                        }
                    }
                    const upsertedEntity = yield this.apis.getMemoByPK(entity);
                    if (upsertedEntity === null) {
                        alert(`failed to get upserted Memo!`);
                        return;
                    }
                    Object.assign(entity, upsertedEntity);
                    this.buildPresentableMemo(w, entity);
                    editButton.style.setProperty('display', 'inline-block');
                    deleteButton.style.setProperty('display', 'inline-block');
                    saveButton.style.setProperty('display', 'none');
                    cancelButton.style.setProperty('display', 'none');
                    this.toggleEdit(false);
                }))();
            });
            w.addFooter(footerBtnContainer);
        };
        this.onClickAddMemoBtn = () => {
            this.buildAndPutMemo((0, entity_1.newEntity)());
        };
        this.fetchItems = () => __awaiter(this, void 0, void 0, function* () {
            const apiResp = yield this.apis.listMemos(this.isPrivate);
            if (apiResp === null) {
                return;
            }
            apiResp.items.sort((a, b) => {
                return parseInt(JSON.parse(a.style_set).zIndex) - parseInt(JSON.parse(b.style_set).zIndex);
            });
            apiResp.items.forEach(item => {
                this.buildAndPutMemo(item);
            });
        });
        this.updateAllStyles = () => __awaiter(this, void 0, void 0, function* () {
            return yield this.apis.updateMemoStyles(this.desktop.getWindows().filter(item => item instanceof lib_es2015_3.Window).map(w => ({
                pk: w.getValue('pk'),
                style_set: JSON.stringify(w.getStyleSet()),
            })));
        });
        this.toggleEdit = (isEdit) => {
            if (isEdit) {
                this.toolbarContainer.style.setProperty('display', 'block');
                this.addMemoBtn.style.setProperty('display', 'none');
            }
            else {
                this.toolbarContainer.style.setProperty('display', 'none');
                this.addMemoBtn.style.setProperty('display', 'block');
            }
        };
        this.style.setProperty('display', 'block');
        // Setup functional style
        if (document.head.querySelector('style[data-scroll-view="true"]') === null) {
            document.head.appendChild((0, lib_es2015_4.buildNodeByOuterHTML)(`<style data-scroll-view="true">
            div.scroll-view {
              height: 100%;
              overflow: auto;
            }
            div.scroll-view:hover::-webkit-scrollbar-thumb {
              background-color: #ddd;
              border-radius: 2px;
            }
            ::-webkit-scrollbar {
              width: 5px;
              height: 5px;
            }
            ::-webkit-scrollbar-track {
              border-radius: 10px;
            }
            ::-webkit-scrollbar-thumb {}
            ::-webkit-scrollbar-corner {
              background-color: inherit;
            }
        </style>`));
        }
        this.innerHTML = `
    <div class="memo-board-container" style="height: 100%;">
      <div class="toolbar-container"></div>
      <button class="add-memo-btn">+</button>
    </div>`;
        this.memoBoardContainer = (0, lib_es2015_4.getQuerySelectOrThrowError)(this, 'div.memo-board-container');
        this.toolbarContainer = (0, lib_es2015_4.getQuerySelectOrThrowError)(this, 'div.toolbar-container');
        this.addMemoBtn = (0, lib_es2015_4.getQuerySelectOrThrowError)(this, 'button.add-memo-btn');
        this.addMemoBtn.addEventListener('click', () => this.onClickAddMemoBtn());
        this.documentEditor = new lib_es2015_2.DocumentEditor(document.createElement('DIV'), // dummy...
        this.toolbarContainer, '/files/customize1/styles/fonts');
        // For loading the CSS document view.
        this.documentViewer = new lib_es2015_2.DocumentViewer();
        this.setupMemoBoard();
        window.onbeforeunload = () => {
            const toolbarDisplay = this.toolbarContainer.style.getPropertyValue('display');
            if (toolbarDisplay === 'block') {
                return 'A memo is on editing and not saved. Do you really want to leave?';
            }
            void (() => __awaiter(this, void 0, void 0, function* () { return yield this.updateAllStyles(); }))();
            // Must return void so that browser does not bring confirming dialog up.
            // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
            return;
        };
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.fetchItems();
        });
    }
}
exports.MemoBoard = MemoBoard;
const defineMemoBoard = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, MemoBoard);
};
exports.defineMemoBoard = defineMemoBoard;
